import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index/Index.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/product/Product.vue')
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import('../views/solution/Solution.vue')
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: () => import('../views/aboutus/AboutUs.vue')
  },
  {
    path: '/partner',
    name: 'Partner',
    component: () => import('../views/partner/Partner.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/news/News.vue')
  },
  {
    path: '/join',
    name: 'Join',
    component: () => import('../views/join/Join.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
